@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer components {
    .toggle {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: red;
        border-radius: 30px;
        cursor: pointer;
    }

    .toggle:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    .toggle-check:checked + .toggle::after {
        left: 49px;
    }
    .toggle-check:checked + .toggle {
        background-color: green;
    }
    .toggle-check {
        display: none;
    }
}
